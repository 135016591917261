.content {
  height: 1920;
  width: 1080;
  padding: 0px;
}

.ant-tabs-ink-bar{
  background:#ff0000 !important;
}

.ant-tabs-tab-btn{
  color:#ffffff !important;
}

.ant-tabs-tab {
  color: #FFFFFF !important;
  background-color:#00000000 !important;
}

.aant-tabs-tab-active {
  font-weight: normal !important;
  color: #363636 !important;
  background-color:#FFFFFF !important;
}

.aant-tabs-nav {
 border-radius: 0px!important;
}

.ant-tabs-nav-operations{} 
.ant-tabs-nav-operations-hidden{}

.commonContent {
  padding: 0px;
  color:#FFFFFF;
  background:#FFFFFF;
}

.ant-table-thead > tr.ant-table-row-hover td,
.ant-table-tbody > tr.ant-table-row-hover td,
.ant-table-thead > tr:hover td,
.ant-table-tbody > tr:hover td{
  background:#AABBFF!important;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-row-hover,
.ant-table-row-hover>td{
  background:#AABBFF!important;
}
.ant-table-fixed.ant-table-row-hover, .ant-table-row-hover>td{
  background:#FFFFFF!important;
}
.aant-table table{
  font-size: 10px;
}
.ant-table-cell{
  padding:1px 1px!important;
}

.aant-tabs-nav {
  border-radius: 0px!important;
 }
 
 .ant-tabs-nav {
   height: 37px!important;
  }
 
 .ant-tabs-top >.ant-tabs-nav{
   margin: 0 0 0 0 !important;
 }
.ant-input .ant-input-disabled {
  background-color: transparent;
  cursor: default;
}
.ant-input[disabled] {
  color: brown;
  background-color: #CCCCCC;
  cursor: default; 
}
.ant-picker.ant-picker-disabled{
  background: #CCCCCC;
  cursor: default; 
}
.ant-picker .ant-picker-input >input[disabled]{
  color: brown;
  cursor: default; 
}
/* .ant-picker .ant-picker-disabled {
  background-color: transparent;
  cursor: default;
}
.ant-picker[disabled] {
  color: brown;
  background-color: #CCCCCC;
  cursor: default; 
} */
 
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
background-color: transparent;
cursor: default;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
cursor: default;

}
.orderdetail-colume1{

}
.orderdetail-colume2{

}