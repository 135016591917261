.SignInContent {
  top:50%;
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  text-align: center;
  padding: 5%;
}

.SignInContent2{
  text-align: center;
}

.TopPadding{
  padding-top: 2%;
}
.centerContent{
  text-align: center;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.centervh {
  border: 0px solid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}