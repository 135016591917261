.content {
  padding: 0px;
}

.ant-tabs-ink-bar{
  background:#ff0000 !important;
}

.ant-tabs-tab-btn{
  color:#ffffff !important;
}

.ant-tabs-tab {
  color: #FFFFFF !important;
  background-color:#00000000 !important;
}

.aant-tabs-tab-active {
  font-weight: normal !important;
  color: #363636 !important;
  background-color:#FFFFFF !important;
}

.aant-tabs-nav {
 border-radius: 0px!important;
}

.ant-tabs-nav-operations{} 
.ant-tabs-nav-operations-hidden{}

.commonContent {
  padding: 0px;
  color:#FFFFFF;
  background:#FFFFFF;
}